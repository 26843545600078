import { chakra, Flex, Link, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { Footer } from "src/components/Footer/Footer";
import { Header } from "src/components/Header/Header";
import { Hx } from "src/components/Heading/Heading";
import { useTranslatedString } from "src/i18n/hooks";
import { Error404 } from "src/icons";
import { SiteSettings } from "src/lib/cms/cms";

export const ErrorPage: React.FC<{
	siteSettings: SiteSettings;
}> = ({ siteSettings }) => {
	const styles = useMultiStyleConfig("ErrorPage", {});
	const t = useTranslatedString();

	const logoData = siteSettings.headerLogo ?? undefined;
	const site = siteSettings.footerSettings.title;

	return (
		<>
			<Header logoData={logoData}>
				{/* TODO: 🚧 FAQ page link and contact button when available */}
				<chakra.div />
			</Header>
			<Flex sx={styles.container}>
				<chakra.div sx={styles.centerWrapper}>
					<Error404 sx={styles.icon} />
					<Hx sx={styles.headline}>{t("errorPage.title.oops")}</Hx>
					<chakra.div sx={styles.description}>
						{/* differing max widths in place to match design. */}
						<chakra.p maxW={{ base: "80%", md: "unset" }}>
							{t("errorPage.description.notFound")}
						</chakra.p>
						<chakra.p maxW={{ base: "85%", md: "unset" }}>
							{t("errorPage.description.findHere")}
						</chakra.p>
					</chakra.div>
					<Link href="/" sx={styles.link} id="click_error_home">
						{t("errorPage.link.homepage", { site })}
					</Link>
				</chakra.div>
			</Flex>
			<Footer
				variant="minimal"
				footerSettings={siteSettings.footerSettings}
			/>
		</>
	);
};

// 🔬 TBD: Please evaluate
