"use client";

import NextError from "next/error";
import { useParams, useSearchParams } from "next/navigation";
import { ErrorPage } from "src/app/components/ErrorPage";
import { SharedContext } from "src/components/SharedContext";
import { SiteSettings } from "src/lib/cms/cms";
import { App } from "./app";

export const Error404 = ({
	allSiteSettings,
}: {
	allSiteSettings: Array<SiteSettings>;
}) => {
	const params = useParams();
	const searchParams = useSearchParams();

	const brandSlug = params.brandSlug;
	const previewBrand = searchParams.get("previewBrand");

	const siteSettings = allSiteSettings.find(
		(s) => s.brandConfig.slug === (previewBrand ?? brandSlug),
	);

	if (!brandSlug || !siteSettings)
		return (
			<html lang="de">
				<head />
				<body>
					<NextError statusCode={404} />
				</body>
			</html>
		);

	return (
		<html lang={siteSettings.brandConfig.locale}>
			<head />
			<body>
				<App>
					<SharedContext siteSettings={siteSettings}>
						<div
							className="flex flex-col min-h-screen"
							data-brand={brandSlug}
						>
							<ErrorPage siteSettings={siteSettings} />
						</div>
					</SharedContext>
				</App>
			</body>
		</html>
	);
};

// 🔬 TBD
